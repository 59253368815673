//Initial State
const initialState = {
    userDetails: {},
    isLoading: false, //loader will shown based on this
    submitResponse: undefined, // success/failure message will be shown,
    isSuccess: false,
};

// reducers.js
//DO NOT CALL SAGA ACTIONS IN THIS REDUCER,AS A CASE STATEMENT
export const userReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'START_LOADER' :
            return {
                ...state,
                isLoading: true,
            };

        case 'STOP_LOADER' :
            return {
                ...state,
                isLoading: false,
            };

        case 'UPDATE_USER_DETAILS':
            return {
                ...state,
                userDetails: action.payload.userDetails,
            }

        case 'UPDATE_USER_SUBMIT_RESPONSE':
            return {
                ...state,
                submitResponse: action.payload.submitResponse,
                isSuccess: action.payload.isSuccess
            };

        default:
            return state;
    }
};