import React from 'react';
import {Link} from 'react-router-dom';

const URL_GARDENIA_COURT = process.env.REACT_APP_URL_GARDENIA_COURT;
const URL_NEWSLETTER_PDF = process.env.REACT_APP_URL_NEWSLETTER_PDF;
const URL_BROCHURE = process.env.REACT_APP_URL_BROCHURE;
const URL_CQC_ORG = process.env.REACT_APP_URL_CQC_ORG;
const URL_FOOD_RATINGS = process.env.REACT_APP_URL_FOOD_RATINGS;
const URL_CAREHOME = process.env.REACT_APP_URL_CAREHOME;
const URL_CAREHOME_REVIEWS = process.env.REACT_APP_URL_CAREHOME_REVIEWS;
const URL_FACEBOOK = process.env.REACT_APP_URL_FACEBOOK;

const LinksFooterContainer = () => {
    return (<>

        <footer id="ji-container-6-footer-1" className="container">
            <div className="container-fluid">
                <div className="margin-only">&nbsp;</div>
                <div className="row">

                    <div className="col-xs-12 col-sm-12 col-md-4  col-lg-4">
                        <ul className="list-unstyled clear-margins">{/* widgets */}

                            <li className="widget-container widget_nav_menu">{/* widgets list */}

                                <h1 className="title-widget">Useful links</h1>
                                <ul>
                                    <li><i className="fa fa-angle-double-right"></i> <Link to="/">Home</Link></li>

                                    <li><i className="fa fa-angle-double-right"></i><Link to="/about">ABOUT US</Link>
                                    </li>

                                    <li><i className="fa fa-angle-double-right"></i><Link to="/services">Services</Link>
                                    </li>
                                    <li><i className="fa fa-angle-double-right"></i><Link to="/care"> Care
                                        Philosophy</Link></li>
                                    <li><i className="fa fa-angle-double-right"></i> <Link to="/gallery">Gallery</Link>
                                    </li>
                                    <li><i className="fa fa-angle-double-right"></i><Link
                                        to="/testimonials">Testimonials</Link></li>

                                    <li><i className="fa fa-angle-double-right"></i><Link
                                        to="/testimonials">Feedback</Link></li>
                                    <li><i className="fa fa-angle-double-right"></i><Link to="/contactUs"> Contact
                                        us</Link></li>

                                </ul>
                            </li>

                        </ul>


                    </div>
                    {/* widgets column left end */}


                    <div className="col-xs-12 col-sm-12 col-md-4  col-lg-4">

                        <ul className="list-unstyled clear-margins">{/* widgets */}

                            <li className="widget-container widget_nav_menu">{/* widgets list */}

                                <h1 className="title-widget">Useful links</h1>

                                <ul>
                                    <li><a href={URL_GARDENIA_COURT} target="_blank"><i
                                        className="fa fa-angle-double-right"></i> Sister Home</a></li>
                                    <li><a href={URL_NEWSLETTER_PDF}
                                           target="_blank"><i className="fa fa-angle-double-right"></i>
                                        Newsletter</a>
                                    </li>

                                    <li><a href={URL_BROCHURE}
                                           target="_blank"><i className="fa fa-angle-double-right"></i> Digital
                                        Brochure</a>
                                    </li>

                                    <li><Link to="/contactUs"><i className="fa fa-angle-double-right"></i> Request a
                                        Brochure</Link>
                                    </li>

                                    <li><a href={URL_CQC_ORG} target="_blank"><i
                                        className="fa fa-angle-double-right"></i> Latest CQC Inspection Report</a>
                                    </li>
                                    <li><a href={URL_FOOD_RATINGS}
                                           target="_blank"><i
                                        className="fa fa-angle-double-right"></i> Food Hygine Rating</a></li>
                                    <li><a href={URL_CAREHOME}
                                           target="_blank"><i className="fa fa-angle-double-right"></i>
                                        Carehome.co.uk</a></li>


                                    <li><a
                                        href={URL_CAREHOME_REVIEWS}
                                        target="_blank"><i className="fa fa-angle-double-right"></i> Reviews</a>
                                    </li>


                                </ul>

                            </li>

                        </ul>


                    </div>
                    {/* widgets column left end */}


                    <div className="col-xs-12 col-sm-12 col-md-4  col-lg-4">


                        <ul className="list-unstyled clear-margins">{/* widgets */}

                            <li className="widget-container widget_recent_news">{/* widgets list */}

                                <h1 className="title-widget">Contact Detail </h1>

                                <div className="footerp">

                                    <h2 className="title-median">Jasmine Court Nursing Home</h2>
                                    <p><b>Email:</b> <a
                                        href="mailto:enquiries@jasminecourt.co.uk">enquiries@jasminecourt.co.uk</a>
                                    </p>
                                    <p><b>Telephone : </b>01934 622 028</p>
                                    <p><b>Fax : </b> 01934 620 916</p>

                                    <p><b>Address : </b>Jasmine Court Nursing Home,<br/>
                                        13, Park place, Weston-Super-Mare,<br/>
                                        Somerset, BS23 2BA</p>
                                </div>

                                <div className="social-icons">

                                    <ul className="nomargin">
                                        <a href={URL_FACEBOOK}
                                           target="_blank"><i className="fa fa-facebook-square fa-2x social-fb"></i></a>
                                        <a href="#"><i className="fa fa-twitter-square fa-2x social-tw"></i></a>
                                        <a href="#"><i className="fa fa-linkedin-square fa-2x social-tw"></i></a>
                                        <a href="#"><i className="fa fa-google-plus-square fa-2x social-gp"></i></a>
                                        <a href="#"><i className="fa fa-rss-square fa-2x social-em"></i></a>

                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>


    </>);
};

export default LinksFooterContainer;