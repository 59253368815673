import {useEffect} from "react";
import {useLocation} from "react-router-dom";

export default function ReactRouterScrollToTop() {
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
};

//https://reactrouter.com/web/guides/scroll-restoration