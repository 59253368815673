export const SURVEY_FORM_FONT_SIZE = {
    small: '18px',
    medium: '20px',
    large: '24px'
};

export const SURVEY_FORM_PADDING_TOP = {
    small: 2,
    medium: 3,
    large: 3,
};
