import React, {Suspense} from "react";
import {BrowserRouter as Router, Switch, Route, Redirect, Link} from 'react-router-dom';
import {Spinner} from 'react-bootstrap';
import {useSelector} from 'react-redux'

import "./App.scss";
import "./styles/main.scss";
import "./styles/jasmine.scss";
import "./styles/navBarSection.scss";
import "./styles/mainBannerSection.scss";
import "./styles/gridLayer.scss";
import "./styles/gridLayerImages.scss";
import "./styles/topBlackLayer.scss";
import "./styles/footer.scss";
import "./styles/jasmine-override.scss";
import "./styles/formApp.scss";
import "./styles/map.scss";
import "./styles/formFeedbackApp.scss";

import LinksFooterContainer from "./components/footer/LinksFooterContainer";
import DocsFooterContainer from "./components/footer/DocsFooterContainer";
import NavigationContainer from "./components/navigation/NavigationContainer";

import HomeExternalScriptWidgets from './components/externalWidget/HomeExternalScriptWidgets';
import ReactRouterScrollToTop from "./utils/ReactRouterScrollToTop";
import CookieConsentBanner from "./utils/CookieConsentBanner";
import StickyLinks from "./components/stickyLinks/StickyLinks";

const LoadingImg = () => {
    return (
        <div className="container">
            <div className="d-flex justify-content-center">
                <Spinner animation="border" variant="secondary" role="status">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        </div>
    );
};

const LazyHome = React.lazy(() => import('./components/home/HomeContainer'));
const LazyAbout = React.lazy(() => import('./components/aboutUs/AboutUsContainer'));
const LazyServices = React.lazy(() => import('./components/services/ServicesContainer'));
const LazyCare = React.lazy(() => import('./components/care/CareContainer'));
const LazyGallery = React.lazy(() => import('./components/gallery/GalleryContainer'));
const LazyTestimonials = React.lazy(() => import('./components/testimonials/TestimonialsContainer'));
const LazyContactUs = React.lazy(() => import('./components/contactUs/ContactUsContainer'));

const LazyDocsCookiePolicy = React.lazy(() => import('./components/policy/CookiePolicy'));
const LazyDocsPrivacyPolicy = React.lazy(() => import('./components/policy/PrivacyPolicy'));
const LazyDocsSiteMap = React.lazy(() => import('./components/policy/SiteMap'));
const LazyDocsTermsOfUse = React.lazy(() => import('./components/policy/TermsOfUse'));
const LazyCustomerSurvey = React.lazy(() => import('./customerSurvey/CustomerSurvey'));
const LazyCustomerSurveyGenerate = React.lazy(() => import('./customerSurvey/CustomerSurveyGenerate'));
const LazyProfessionalSurvey = React.lazy(() => import('./customerSurvey/ProfessionalSurvey'));
const LazyProfessionalSurveyGenerate = React.lazy(() => import('./customerSurvey/ProfessionalSurveyGenerate'));


function App() {
    const showExternalWidgets = useSelector(state => state.shared.showExternalWidgets);
    const showCustomerSurvey = useSelector(state => state.shared.showCustomerSurvey);

    return (
        <Router>
            <ReactRouterScrollToTop/>
            <CookieConsentBanner />
            {/* <StickyLinks /> */}

            {!showCustomerSurvey && (
                <>
                    <div className="container">
                        <NavigationContainer/>
                        
                    </div>
                    <StickyLinks />

                    <div className="container">
                        <div className="container-fluid j-link-top-layer">
                            &nbsp;
                        </div>
                    </div>

                </>
            )}

            <Suspense fallback={<LoadingImg/>}>
                <Switch>
                    <Route exact path="/">
                        <LazyHome/>
                    </Route>

                    <Route path="/about">
                        <LazyAbout/>
                    </Route>
                    <Route path="/services">
                        <LazyServices/>
                    </Route>
                    <Route path="/care">
                        <LazyCare/>
                    </Route>
                    <Route path="/gallery">
                        <LazyGallery/>
                    </Route>
                    <Route path="/testimonials">
                        <LazyTestimonials/>
                    </Route>

                    <Route exact path="/contactUs">
                        <LazyContactUs/>
                    </Route>

                    <Route path="/contactUs/:formId">
                        <LazyContactUs/>
                    </Route>

                    <Route path="/sitemap">
                        <LazyHome/>
                    </Route>

                    <Route path="/policy/terms">
                        <LazyDocsTermsOfUse/>
                    </Route>

                    <Route path="/policy/cookie">
                        <LazyDocsCookiePolicy/>
                    </Route>

                    <Route path="/policy/privacy">
                        <LazyDocsPrivacyPolicy showHeading={true}/>
                    </Route>
                    <Route path="/survey/:encryptedDate">
                        <LazyCustomerSurvey/>
                    </Route>
                    <Route exact path="/generate/jjyhhJJJul6877jHHHlgk99KK5876hsfksfhkhskfh/:surveyDate">
                        <LazyCustomerSurveyGenerate/>
                    </Route>

                    <Route path="/p-survey/:encryptedDate">
                        <LazyProfessionalSurvey/>
                    </Route>

                    <Route exact path="/generateprofessional/hkdksgsk97Jlhiyrwkshfksirybbutshfddssfi3355fgsojsf/:surveyDate">
                        <LazyProfessionalSurveyGenerate/>
                    </Route>

                    <Route path='*' render={() =>
                        (
                            <Redirect to="/"/>
                        )
                    }/>

                </Switch>

            </Suspense>

            {!showCustomerSurvey && (
                <>
                    <div id="ji-container-4B-review-logo" className="container j-grid-container"
                         style={{display: showExternalWidgets ? 'block' : 'none'}}>
                        <div className="container-fluid">
                            <HomeExternalScriptWidgets/>

                        </div>
                    </div>

                    <div id="ji-line-separator" className="container">
                        <div className="container-fluid j-line-separator">
                            &nbsp;
                        </div>

                    </div>

                    <LinksFooterContainer/>




                    <DocsFooterContainer/>
                </>
            )}
        </Router>
    );
}

export default App;
