import React from "react";
import "./StickyLinks.css";
import { Link } from "react-router-dom";

const URL_GARDENIA_COURT = process.env.REACT_APP_URL_GARDENIA_COURT;
const URL_NEWSLETTER_PDF = process.env.REACT_APP_URL_NEWSLETTER_PDF;
const URL_BROCHURE = process.env.REACT_APP_URL_BROCHURE;
// const URL_CQC_ORG = process.env.REACT_APP_URL_CQC_ORG;
// const URL_FOOD_RATINGS = process.env.REACT_APP_URL_FOOD_RATINGS;
// const URL_CAREHOME = process.env.REACT_APP_URL_CAREHOME;
// const URL_CAREHOME_REVIEWS = process.env.REACT_APP_URL_CAREHOME_REVIEWS;
// const URL_FACEBOOK = process.env.REACT_APP_URL_FACEBOOK;

const StickyLinks = () => {
  return (
    <div className="container">
      <div className="container-relative-custom">
        <div className="absolute-container">
          <div className="icon-bar">
            <Link to="/contactUs" className="icon-1">
              <div className="text-1">
                {" "}
                <i className="fa fa-2x-custom fa-phone"></i>
              </div>
            </Link>

            <a href={URL_BROCHURE} className="icon-2" target="_blank">
              <div className="text-2">
                {" "}
                {/* <i className="fa fa-2x-custom fa-book"></i> */}
                <i className="fa fa-2x-custom fa-book-open"></i>
              </div>
            </a>

            <a href={URL_NEWSLETTER_PDF} className="icon-3" target="_blank">
              <div className="text-3">
                {" "}
                <i className="fa fa-2x-custom fa-newspaper"></i>
              </div>
            </a>

            <a href={URL_GARDENIA_COURT} className="icon-4" target="_blank">
              <div className="text-4">
                {" "}
                <i className="fa fa-2x-custom fa-building"></i>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StickyLinks;
