import {applyMiddleware, combineReducers, createStore,} from 'redux';

import {composeWithDevTools} from 'redux-devtools-extension';

import {reducer as formReducer} from 'redux-form'
import {userReducer} from './UserReducer';
import {sharedReducer} from './SharedReducer';

import createSagaMiddleware from "redux-saga"; //new for saga
import rootSaga from './saga/RootSaga'; //new for saga
const sagaMiddleware = createSagaMiddleware(); //new for saga

const rootReducer = combineReducers({
    // ...your other reducers here
    // you have to pass formReducer under 'form' key,
    // for custom keys look up the docs for 'getFormState'
    form: formReducer,
    user: userReducer,
    shared: sharedReducer,
});

// store.js
export const store = createStore(rootReducer, composeWithDevTools(
    applyMiddleware(sagaMiddleware)
));

sagaMiddleware.run(rootSaga);