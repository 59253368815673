import React from "react";
import './HomeExternalScriptWidgets.scss';

const API_FOOD_RATINGS_WIDGET = process.env.REACT_APP_API_FOOD_RATINGS_WIDGET;

class HomeExternalScriptWidgets extends React.Component {
    constructor(props) {
        super(props);
        this.myRef1 = React.createRef();
    }

    componentDidMount() {
        const script1 = document.createElement("script");
        script1.src = process.env.REACT_APP_API_CQC_WIDGET;
        script1.async = true;

        this.myRef1.current.appendChild(script1);
    }

    render() {
        return (

            <div className="row">

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 text-center responsive-gap">
                    <a href={API_FOOD_RATINGS_WIDGET} target="_blank"><img
                        className="img-fluid margin" src="/img/reviewLogo/foodAgency.png"/></a>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 text-center responsive-gap">
                    <div className='tg-review-widget-container' id='tgrw-4fb79729'></div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 text-center responsive-gap">
                    <div ref={this.myRef1}></div>
                </div>

                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-3 text-center responsive-gap">
                    <div className='tg-review-widget-container' id='tgrw-3a4abfb7'></div>
                </div>
            </div>

        );
    }
}

export default HomeExternalScriptWidgets;

