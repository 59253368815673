import React from 'react';

const CookiePolicy = ({showHeading = true}) => {
    return (<>
        {showHeading && (<div id="ji-l10-cont-1" className="container">
            <div className="container-fluid bg-testimonials-1 text-center j-pages-container-title">
                <div className="margin-only">&nbsp;</div>
                <div className="row">

                    <div className="col-xs-12 col-sm-12 col-md-9 col-lg-9">
                        <p>COOKIE POLICY</p><br/>
                    </div>
                </div>

                <div className="margin-only">&nbsp;</div>
            </div>
        </div>)}

        <div id="ji-l10-cont-2" className="container">
            <div className="container-fluid bg-testimonials-2  j-header-para-justify">
                <div className="margin-only">&nbsp;</div>

                <div className="j-para-group">
                    <div className="j-para-sub-header">COOKIE POLICY</div>
                    <div className="j-para-sub-body">

                        <div className="j-para-sub-para">
                            This site uses cookies – small text files that are placed on your machine to help the
                            site provide a better user experience.
                        </div>

                        <div className="j-para-sub-para">
                            When you visit any web site, it may store or retrieve information on your browser,
                            mostly in the form of cookies. This information is mostly used to make the site work as
                            you expect it to.
                        </div>

                        <div className="j-para-sub-para">
                            Because we respect your right to privacy, you can choose not to allow some types of
                            cookies.
                        </div>

                        <div className="j-para-sub-para">
                            During the course of any visit on our website, the pages you see are downloaded to your
                            computer. Many web sites will also download a short text file called a ‘cookie’ along
                            with the web pages you are looking at.
                        </div>

                        <div className="j-para-sub-para">
                            Cookies set by website owner (www.jasminecourt.co.uk) are called ‘first party cookies’.
                            Cookies set by parties other than the website owner are called ‘third party cookies’.
                            Third party cookies enable third party features or functionality to be provided on or
                            through the website (eg maps, review, analytics etc)
                        </div>

                        <div className="j-para-sub-para">
                            Jasmine Court Nursing Home currently use both first and third party cookies in order for
                            our website to operate.
                        </div>

                        <div className="j-para-sub-para">
                            Cookies enable website publishers to do useful things like find out whether the computer
                            (and probably its user) has visited the website before. This is done on a repeat visit
                            by checking to see, and finding, the cookie left there on the last visit.
                        </div>

                        <div className="j-para-sub-para">
                            Information supplied by cookies can help the web publisher to provide you with a better
                            online user experience and assist the company in analysing the profile of the visitors.
                        </div>

                    </div>
                </div>

                <div className="margin-only">&nbsp;</div>
            </div>
        </div>

    </>);
};

export default CookiePolicy;