import {call, put, takeLatest} from "redux-saga/effects";
import axios from 'axios';

const API_PATH = process.env.REACT_APP_API_CONTACT_US_FORM_PATH;

const convertToFormData = (user) => {
    //https://stackoverflow.com/questions/43085762/how-to-pass-form-values-as-formdata-in-reactjs-on-submit-function

    let formData = new FormData();    //formdata object

    formData.append('contactAgreeEmailConsent', user.contactAgreeEmailConsent);
    formData.append('contactAgreePostConsent', user.contactAgreePostConsent);
    formData.append('contactAgreePrivacyPolicy', user.contactAgreePrivacyPolicy);
    formData.append('contactBrochureRequired', user.contactBrochureRequired);
    formData.append('contactEmail', user.contactEmail);
    formData.append('contactHouseCity', user.contactHouseCity);
    formData.append('contactHouseNo', user.contactHouseNo);
    formData.append('contactHousePostcode', user.contactHousePostcode);
    formData.append('contactHouseStreet', user.contactHouseStreet);
    formData.append('contactMessage', user.contactMessage);
    formData.append('contactName', user.contactName);
    formData.append('contactTel', user.contactTel);
    return formData;
}

//Need to send formData() object to php , and not json. therefore will need to conver the json object to formData
function saveUser(user) {
    const config = {
        headers: {'content-type': 'multipart/form-data'}
    }
    return axios.post(API_PATH, convertToFormData(user), config);
};

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

function* submitContactUsFormSaga(action) {
    try {
        const {payload} = action;
        console.log('payload==', payload);

        yield put({
            type: 'START_LOADER'
        });

        const user = payload.user; //action.payload.user;
        const result = yield call(saveUser, user);

        yield put({
            type: 'UPDATE_USER_DETAILS',
            payload: {
                userDetails: result.data,
            },
        });

        yield put({
            type: 'UPDATE_USER_SUBMIT_RESPONSE',
            payload: {
                submitResponse: 'Submitted successfully!',
                isSuccess: true,
            },
        });

        yield put({
            type: 'STOP_LOADER'
        });

        yield call(sleep, 5000);

        yield put({
            type: 'UPDATE_USER_SUBMIT_RESPONSE',
            payload: {
                submitResponse: undefined,
                isSuccess: true,
            },
        });

    } catch (e) {
        console.error('Error==', e);
        yield put({
            type: 'STOP_LOADER'
        });

        yield put({
            type: 'UPDATE_USER_DETAILS',
            payload: {
                userDetails: {},
            },
        });

        yield put({
            type: 'UPDATE_USER_SUBMIT_RESPONSE',
            payload: {
                submitResponse: 'Error while submitting data, please try again',
                isSuccess: false,
            },
        });

        yield call(sleep, 5000);

        yield put({
            type: 'UPDATE_USER_SUBMIT_RESPONSE',
            payload: {
                submitResponse: undefined,
                isSuccess: false,
            },
        });

    }

}

const submitContactUsFormSagaHandler = function* submitContactUsFormSagaHandler() {
    yield takeLatest('SUBMIT_CONTACT_US_FORM', submitContactUsFormSaga);
};

export default submitContactUsFormSagaHandler;
