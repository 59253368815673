import {SURVEY_FORM_FONT_SIZE, SURVEY_FORM_PADDING_TOP} from '../constants/constants';


const initialState = {
    showExternalWidgets: false,
    showCustomerSurvey: false,
    surveyFormFontSize: SURVEY_FORM_FONT_SIZE.small,
    surveyFormBoxMargin: SURVEY_FORM_PADDING_TOP.small

};

// reducers.js
//DO NOT CALL SAGA ACTIONS IN THIS REDUCER,AS A CASE STATEMENT

export const sharedReducer = (state = initialState, action) => {
    switch (action.type) {

        case 'HIDE_EXTERNAL_WIDGETS':
            return {
                ...state,
                showExternalWidgets: false,
            };

        case 'SHOW_EXTERNAL_WIDGETS':
            return {
                ...state,
                showExternalWidgets: true,
            };

        case 'SHOW_CUSTOMER_SURVEY':
            return {
                ...state,
                showCustomerSurvey: true,
            };

        case 'HIDE_CUSTOMER_SURVEY':
            return {
                ...state,
                showCustomerSurvey: false,
            };

        case 'SURVEY_FORM_SMALL_FONT':
            return {
                ...state,
                surveyFormFontSize: SURVEY_FORM_FONT_SIZE.small,
                surveyFormBoxMargin: SURVEY_FORM_PADDING_TOP.small
            };

        case 'SURVEY_FORM_MEDIUM_FONT':
            return {
                ...state,
                surveyFormFontSize: SURVEY_FORM_FONT_SIZE.medium,
                surveyFormBoxMargin: SURVEY_FORM_PADDING_TOP.medium
            };

        case 'SURVEY_FORM_LARGE_FONT':
            return {
                ...state,
                surveyFormFontSize: SURVEY_FORM_FONT_SIZE.large,
                surveyFormBoxMargin: SURVEY_FORM_PADDING_TOP.large
            };

        default:
            return state;
    }
};