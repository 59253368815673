import React from 'react';
import CookieConsent from "react-cookie-consent";
import CookiePolicyModal from "../components/policy/CookiePolicyModal";
import styled from '@emotion/styled';

const CookieConsentBanner = (props) => {

    const AnchorDiv = styled.div`
  
    a{
    color: white;
    text-decoration: underline;
    display: inline-block;
    margin: 0 0 5px 10px;
    }
    
    a:visited {
    color: white
    
    `;

    const [cookieModalShow, setCookieModalShow] = React.useState(false);

    const showModalHandler = React.useCallback((event) => {
        event.preventDefault();
        setCookieModalShow(true);
    }, [cookieModalShow]);

    const accepted = () => {
        console.log('accepted');
    }

    return (
        <>
            <CookiePolicyModal show={cookieModalShow} onHide={() => setCookieModalShow(false)}/>

            <CookieConsent
                location="bottom"
                buttonText="Got it!"
                cookieName="jasminecourtnursinghome-cookie-consent"
                style={{background: "#174663"}}
                buttonStyle={{
                    color: "#174663",
                    fontSize: "13px",
                    background: "white",
                    border: "none",
                    borderRadius: "4px"
                }}
                expires={150}
                overlay={true}
            >

                <AnchorDiv> This website uses cookies to ensure you get the best experience on our website.<span> <a
                    href="/"
                    onClick={showModalHandler}>Learn
                    more</a></span>
                </AnchorDiv>

            </CookieConsent>
        </>

    );
}

export default CookieConsentBanner;
